import Image from 'next/image'
export default function VipHomeSlider({ title, image, description, intro }) {
  return (
    <div className="tw-flex tw-items-end sm:tw-items-center  tw-h-full tw-bg-black tw-text-white tw-relative">
      <Image
        fill={true}
        src={image}
        alt="slide"
        className="tw-object-cover image-filter"
      />
      <div className="vip-container tw-fixed tw-z-10 md:tw-max-w-[48vw] tw-w-full md:tw-mb-[10vh] tw-mb-[35vh] tw-flex tw-flex-col [&>p]:tw-text-white [&>p]:md:tw-text-lg [&>p]:tw-text-sm ">
        <p>{intro}</p>
        <h1
          className="text-gradient-gold sm:tw-text-[4.5vh] sm:!tw-leading-[120%] tw-text-3xl  tw-mb-4 tw-py-2 md:tw-py-[1.5vh] tw-font-shadow [text-shadow:_0px_1px_1px_rgb(0_0_0_/_10%)] !tw-font-satoshiRealBold"
          dangerouslySetInnerHTML={{ __html: title }}
        ></h1>
        <p className="!tw-font-satoshi">{description}</p>
      </div>
    </div>
  )
}
