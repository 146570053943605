import { VIP_INSTANCE } from '@/lib/api'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

const useVipAuth = create(
  devtools((set, get) => ({
    isUserMeLoading: false,
    userMe: null,
    setUserMe: (userMe) => set({ userMe }),
    shouldRedirectIfUnauthenticated: (locale) => {
      const isLoading = get().isUserMeLoading
      const userMe = get().userMe
      if (isLoading) return
      if (userMe) return
      if (window.location.pathname.includes('/beyond/dashboard')) {
        window.location.href = `${locale == 'th' ? '' : `/${locale}`}/beyond/auth/login?method=unselected&destination=${window.location.pathname}`
      }
    },
    mayFetchUserMe: async (token, locale) => {

      set({ isUserMeLoading: true })

      // return promise resolve
      return new Promise((resolve, reject) => {
        VIP_INSTANCE.post('/vip/v1/me', { token })
          .then(({ data }) => {
            if (data?.ID) {
              set({ userMe: data })
            }
            resolve()
          })
          .catch((err) => {
            // console.error('login error', err?.response)
            get().shouldRedirectIfUnauthenticated(locale)
            reject()
          })
          .finally(() => set({ isUserMeLoading: false }))
      })
    },
    logout: async (token, locale) => {
      try {
        const res = await VIP_INSTANCE.post(`/vip/v1/logout`, { token })
        //redirect to login page
        if (res.data?.status === 'success') {
          window.location.href = `/beyond?logout=true`
        }
      } catch (err) {
        console.error(err?.response?.data)
        return err?.response?.data
      }
    },
  }))
)

export default useVipAuth
