// WELCOME PAGE
'use client'
import CCP_MySwiper from '@/components/main/swiper/MySwiper'
//import mui button
import VipHeader from '@/components/vip/VipHeader'
import VipHomeSlider from '@/components/vip/VipHomeSlider'
import { CMS_INSTANCE } from '@/lib/api'
import { Link } from '@/navigation'
import useVipAuth from '@/store/vipAuth'
import useVipMeta from '@/store/vipMeta'
import onlyThEn from '@/utils/onlyThEn'
import Button from '@mui/material/Button'
import { useLocale } from 'next-intl'
import { useReCaptcha } from 'next-recaptcha-v3'
import { redirect, useSearchParams } from 'next/navigation'
import { useEffect, useState } from 'react'

export default function BBeyondHome() {
  const { executeRecaptcha, loaded: isRecaptchaLoaded } = useReCaptcha()
  const searchParams = useSearchParams()
  const { userMe } = useVipAuth()
  const [contactMethod, setContactMethod] = useState('')
  const [verifying, setVerifying] = useState(false)
  const [loginValue, setLoginValue] = useState('')
  const locale = useLocale()
  const meta = useVipMeta((s) => s.meta)
  const become = meta?.become_a_member_section[`${onlyThEn(locale)}`]
  const inv = searchParams.get('inv')

  useEffect(() => {
    if (!inv) {
      return
    }

    const getInv = async () => {
      try {
        setVerifying(true)
        const token = await executeRecaptcha('vip_verify_invitation')
        const response = await CMS_INSTANCE.post('vip/v1/verify-invitation', {
          inv,
          token,
        })

        setContactMethod(response?.data?.method)
        setLoginValue(response?.data?.vip_user_login)
      } catch (error) {
        if (error?.response?.data === 'Invalid request') {
          alert('Invalid Invitation')
        }
      } finally {
        setVerifying(false)
      }
    }
    getInv()
  }, [inv, isRecaptchaLoaded, executeRecaptcha])

  if (userMe) {
    // Redirect to dashboard if user is already logged in
    redirect(`${locale == 'th' ? '' : `/${locale}`}/beyond/dashboard`)
  }

  const homeSlides = [
    {
      intro: "You're Invited to",
      title: 'The Exclusive Privileges',
      description:
        'Click “Activate / Log in” to access your personalized wellness benefits at Bangkok Hospital ... or swipe to learn more',
      image: '/vip/vip-welcome-bg.jpg',
    },
    {
      intro: 'Exclusive',
      title: 'Lounge',
      description: 'Experience tranquility and privacy in our exclusive lounge',
      image: '/vip/lounge.jpg',
    },
    // {
    //   intro: 'Dedicated',
    //   title: 'Personal Escort',
    //   description:
    //     'Enjoy seamless, personalized care from arrival to departure',
    //   image: '/vip/hospital-escort.jpg',
    // },
    {
      intro: 'Bespoke',
      title: 'Birthday Gifts',
      description: 'Special Gifts for your special day',
      image: '/vip/birthday-gift2.jpg',
    },
    {
      intro: 'Special',
      title: 'Exclusive Events',
      description:
        'Engage with health experts and join the elite wellness community',
      image: '/vip/exclusive-events.jpg',
    },
  ]

  return (
    <>
      <VipHeader
        isLogoInvertedMobile={true}
        isPhoneInvertedMobile={true}
        isLogoInvertedDesktop={true}
        isPhoneInvertedDesktop={true}
      />
      <CCP_MySwiper
        slidesPerView={1}
        spaceBetween={0}
        speed={1000}
        pagination={true}
        navigation={true}
        loop={false}
        swiperClass="vip home inverted"
        swiperSlideClass="tw-min-h-screen tw-bg-vip-primary"
        slides={homeSlides.map((data, i) => (
          <VipHomeSlider
            key={`vip-slide-${i}`}
            intro={data.intro}
            title={data.title}
            image={data.image}
            description={data.description}
          ></VipHomeSlider>
        ))}
      />

      <div className="tw-absolute tw-z-10 tw-bottom-[40px] sm:tw-bottom-[22vh] tw-w-full tw-flex tw-justify-center tw-items-center sm:tw-justify-start tw-px-8 vip-container">
        {!verifying ? (
          <div className="tw-flex tw-flex-col tw-w-full sm:tw-flex-row tw-justify-center sm:tw-justify-start tw-items-center">
            <Link
              prefetch={false}
              href={`/beyond/auth/login?method=${
                contactMethod ? contactMethod : 'unselected'
              }${loginValue ? `&login=${loginValue}` : ''}`}
            >
              <Button className="vip-button-light tw-w-full sm:tw-max-w-[280px] !tw-font-satoshi">
                Activate / Login
              </Button>
            </Link>
            ){' '}
            <Link
              prefetch={false}
              href="/beyond/membership"
              className="tw-text-white/80 hover:tw-text-white tw-w-full tw-underline tw-underline-offset-8 tw-decoration-white/20 hover:tw-decoration-white tw-transition-all tw-mt-5 sm:tw-mt-0 sm:tw-ml-12 tw-text-center sm:tw-text-left sm:tw-max-w-[280px] !tw-font-satoshi"
            >
              {become?.title || 'Become a Member'}
            </Link>
          </div>
        ) : (
          <i className="far fa-spinner fa-spin tw-text-white"></i>
        )}
      </div>
    </>
  )
}
