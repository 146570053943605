import { CMS_INSTANCE } from '@/lib/api'
import { unstable_cache } from 'next/cache'
import { create } from 'zustand'

const useVipMeta = create((set, get) => ({
  meta: null,
  fetchVipMeta: async () => {
    const metaURL = '/vip/v1/meta'
    const { data } = await CMS_INSTANCE.get(metaURL)
    set({ meta: data.meta })
  }
}))

export default useVipMeta
