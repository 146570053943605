'use client'
import useVipMeta from '@/store/vipMeta'
import { Menu } from '@mui/material'
import Image from 'next/image'
import { useState } from 'react'
import TouchRipple from '../main/element/TouchRipple'
import LanguageSwitcherListItem from '../main/header/LanguageSwitcherListItem'

export default function VipHeader({
  isLogoInvertedMobile = false,
  isPhoneInvertedMobile = false,
  isLogoInvertedDesktop = false,
  isPhoneInvertedDesktop = false,
  hideOnMobile = false,
}) {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const meta = useVipMeta((s) => s.meta)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <nav
      className={`tw-justify-between tw-p-4 tw-w-full tw-absolute tw-z-20 ${
        hideOnMobile ? 'tw-hidden md:tw-flex' : 'tw-flex'
      }`}
    >
      <TouchRipple
        prefetch={false}
        href="/beyond"
        className="tw-relative tw-w-[122px] tw-h-auto tw-min-h-[32px] -dark"
      >
        <Image
          src={isLogoInvertedMobile ? '/logo-white.svg' : '/bgh_logo.svg'}
          fill={true}
          alt="Bangkok Hospital"
          className="tw-w-auto tw-h-auto tw-block md:tw-hidden"
          priority={true}
        />
        <Image
          src={isLogoInvertedDesktop ? '/logo-white.svg' : '/bgh_logo.svg'}
          fill={true}
          alt="Bangkok Hospital"
          className="tw-w-auto tw-h-auto tw-hidden md:tw-block"
          priority={true}
        />
      </TouchRipple>

      {/* Mobile */}
      <div className="tw-gap-4 tw-flex md:tw-hidden">
        <TouchRipple
          prefetch={false}
          isATag={true}
          href="#change-language"
          className={`-dark tw-flex md:tw-hidden  tw-items-center tw-justify-center tw-w-12 tw-h-12 tw-border tw-rounded-full tw-min-w-12 ${
            isPhoneInvertedMobile
              ? 'tw-border-white/20 tw-text-white'
              : 'tw-border-gray/20 tw-text-black'
          }`}
          id="lang-button-mobile"
          aria-controls={open ? 'lang-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <i className="fas fa-globe" />
        </TouchRipple>

        <TouchRipple
          prefetch={false}
          isATag={true}
          href={`tel:${meta?.vip_phone_number}`}
          className={`-dark tw-flex md:tw-hidden tw-items-center tw-justify-center tw-w-12 tw-h-12 tw-border tw-rounded-full tw-min-w-12 ${
            isPhoneInvertedMobile
              ? 'tw-border-white/20 tw-text-white'
              : 'tw-border-gray/20 tw-text-black'
          }`}
        >
          <i className="fas fa-phone" />
        </TouchRipple>
      </div>

      {/* Desktop */}
      <div className="tw-gap-4 tw-hidden md:tw-flex">
        <TouchRipple
          prefetch={false}
          isATag={true}
          href="#change-language"
          className={`-dark tw-hidden md:tw-flex tw-items-center tw-justify-center tw-w-12 tw-h-12 tw-border tw-rounded-full tw-min-w-12 ${
            isPhoneInvertedDesktop
              ? 'tw-border-white/20 tw-text-white'
              : 'tw-border-gray/20 tw-text-black'
          }`}
          id="lang-button"
          aria-controls={open ? 'lang-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <i className="fas fa-globe" />
        </TouchRipple>
        <TouchRipple
          prefetch={false}
          isATag={true}
          href={`tel:${meta?.vip_phone_number}`}
          className={`-dark tw-hidden md:tw-flex tw-items-center tw-justify-center tw-w-12 tw-h-12 tw-border tw-rounded-full tw-min-w-12 ${
            isPhoneInvertedDesktop
              ? 'tw-border-white/20 tw-text-white'
              : 'tw-border-gray/20 tw-text-black'
          }`}
        >
          <i className="fas fa-phone" />
        </TouchRipple>
      </div>
      <Menu
        id="lang-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lang-button',
        }}
      >
        <LanguageSwitcherListItem showOnly={['th', 'en']} />
      </Menu>
    </nav>
  )
}
